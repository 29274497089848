export const mapa_rutas_informes = new Map([
  ["INFORMES-MAESTROS", ["/sin-ruta"]],
  ["INFORMES-CONTABILIDAD", [
    "/informes-contabilidad?comisiones-conductores",
    "/informes-contabilidad?retencion-ica",
    "/informes-contabilidad?comisiones-comerciales",
    "/informes-contabilidad?ingresos-por-origen",
    "/informes-contabilidad?informacion-contable",
    "/informes-contabilidad?informacion-contable-externo",
    "/informes?auxiliar",
    "/informes?auxiliar-terceros",
    "/informes?balance",
    "/informes?pyg",
    "/informes?medios",
    "/informes?asientos-bancarios",
    "/informes?siplaft"]],
  ["INFORME-TRANSPORTE", [
    "/informe-transporte?general-manifiestos",
    "/informe-transporte?sin-facturar",
    "/informe-transporte?sin-liquidar",
    "/informe-transporte?manifiesto-sin-pago",
    "/informe-transporte?con-sin-pagos",
    "/informe-transporte?manifiestos-facturados",
    "/sin-ruta",
    "/sin-ruta",
    "/sin-ruta",
    "/sin-ruta"]],
  ["INFORMES-DOCUMENTOS", [
    "/informes-documentos?informe-facturacion",
    "/informes-documentos?informe-cartera"]],
  ["INFORME-INVENTARIO", ["/informe-inventario"]],
  ["INFORME-TRANSACCIONES", ["/informe-transacciones"]]
]);
