import useUtilidades from "@/composables/utilidades";
import { IEstatus, IResultado } from "@/interfaces/requests";
import router from "@/router";
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import { mapa_rutas_informes } from "@/components/sidebar/mapa-informes";

export const manejadorRutas = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { obtenerInfoUsuario } = useUtilidades();
  const datos_usuario = obtenerInfoUsuario();
  const permisos = datos_usuario?.permisos;

  if (to.name === "login") {
    next();
  } else {
    const ruta = permisos?.some((item) => {
      const ruta = to.path
        .replaceAll("/", "*")
        .split("*")[1]
        .toLocaleLowerCase();
      return item.id.toLowerCase().includes(ruta);
    });
    return ruta || datos_usuario?.es_super ? next() : next({ path: "/" });
  }
};

export const resolverRutaPorPermisos = (datos_rol: IResultado) => {
  if (datos_rol.statusCode !== IEstatus.OK) throw new Error(datos_rol.message);
  sessionStorage.setItem("complementarios", JSON.stringify(datos_rol.data));
  const { obtenerInfoUsuario } = useUtilidades();
  const datos_usuario = obtenerInfoUsuario();
  const permiso = datos_usuario?.permisos?.[0];
  const [ruta] = permiso.id.split("/");
  const ruta_final = mapa_rutas_informes.get(ruta)?.[0] ?? ruta;
  setTimeout(() => {
    let objeto: RouteLocationRaw = {
      path: datos_usuario.es_super
        ? "/tablero-general"
        : ruta_final?.toLowerCase(),
    };
    const partes = ruta_final?.split("?");

    if (partes.length > 1) {
      const [parte1, parte2] = partes;
      objeto = { path: parte1.toLowerCase(), query: { tipo: parte2 } };
    }

    router.push(objeto);
  }, 1000);
};
