import type { IBase, ListasEstandarFormulario } from "./base";
import { TipoTransporteEnum } from "./manifiesto";

export enum TomadorPolizaEnum {
  Destinatario = "D",
  EmpresaTransporte = "E",
  GeneradorCarga = "G",
  NoExistePoliza = "N",
  Remitente = "R",
}

type TomadorPolizaTipo =
  | TomadorPolizaEnum.Destinatario
  | TomadorPolizaEnum.EmpresaTransporte
  | TomadorPolizaEnum.GeneradorCarga
  | TomadorPolizaEnum.NoExistePoliza
  | TomadorPolizaEnum.Remitente;

export interface SeguroTransporte {
  valor: TomadorPolizaTipo;
  campo: string;
}

interface IBaseProducto {
  producto_id: number | null;
  cantidad: number | null;
  valor_a_cobrar: number | null;
  valor_a_pagar: number | null;
}

export interface IRemesa extends IBase {
  remesa_id?: number;
  orden_cargue_id: number;
  numero_documento: string;
  fecha_documento: string;
  fecha_hora_cargue: string;
  fecha_hora_descargue: string;
  permitir_rentabilidad_baja: boolean;
  cliente_facturar_id: number | null;
  horas_pactadas_cargue: number | null;
  horas_pactadas_descargue: number | null;
  direccion_cliente_facturar_id: number | null;
  forma_pago_id: number | null;
  termino_pago: number | null;
  tomador_poliza: string;
  aseguradora_id: number | null;
  numero_poliza: string;
  fecha_vencimiento_poliza: string;
  remitente_mercancia_id: number | null;
  direccion_remitente_id: number | null;
  destinatario_mercancia_id: number | null;
  direccion_destinatario_id: number | null;
  observaciones: string;
  descripcion_corta: string;
  tipo_empaque: string;
  unidad_medida: string;
  producto_id: number | null;
  cantidad: number | null;
  valor_cobrar: number | null;
  valor_pagar: number | null;
  estado: string;
  tipo_documento_id: number | null;
  destinatario_mercancia?: string;
  cliente_a_facturar?: string;
  remitente_mercancia?: string;
  tipo_transporte?: string;
  naturaleza_carga: string;
  configuracion?: {
    responsable_poliza: string;
    responsable_aseguradora: string;
    responsable_numero_poliza: string;
    responsable_vencimiento_poliza: string;
  };
  producto?: string;
  tipo_operacion?: string | null;
  autorizacion_remesa?: string;
  aut_cumplir_remesa?: string;
  num_anular_cumplido?: string;
  paquetes: number;
  remision: string | null;
  peso_contenedor_vacio: number;
  tipo_manifiesto?: string;
}

export interface IServicio extends IBase {
  servicios_otro_proveedor_id?: number;
  orden_cargue_id: number;
  numero_documento: string;
  fecha_servicio: string;
  cliente_facturar_id: number | null;
  direccion_cliente_facturar_id: number | null;
  forma_pago_id_cliente: number | null;
  termino_pago_cliente: number;
  proveedor_pagar_id: number | null;
  direccion_proveedor_id: number | null;
  forma_pago_id_proveedor: number | null;
  termino_pago_proveedor: number;
  observaciones: string;
  estado: string;
  tipo_documento_id: number;
  cliente?: string;
  proveedor?: string;
  producto?: string;
}

export interface IServicioOtrosProveedoresLinea extends IBase, IBaseProducto {
  servicios_otro_proveedor_lineas_id?: number;
  servicios_otro_proveedor_id: number | null;
  producto?: string;
}

export interface IRemesaLinea extends IBase, IBaseProducto {
  remesa_linea_id?: number;
  remesa_id: number | null;
  producto?: string;
  creado?: string;
  actualizado?: string;
  codigo_servicio?: string;
  pendiente?: boolean;
}

export enum EstadoDocumento {
  BORRADOR = "BO",
  COMPLETADO = "CO",
  ANULADO = "AN",
  REACTIVAR = "RE",
  CUMPLIDO = "CU",
  FACTURADO = "FA",
  LIQUIDADO = "LI",
  FACTURADO_LIQUIDADO = "FALI",
  RE_FACTURADO = "REFA",
  CUMPLIDO_INICIAL_CARGUE = "CIC",
  CUMPLIDO_INICIAL_DESCARGUE = "CID",
  CUMPLIDO_MUNICIPAL = "CUM",
  AUTORIZADO = "AU",
  AUTORIZADO_MUNICIPAL = "AUR",
  REPORTADO = "RERNDC",
  NOTA_CREDITO = "NCRO",
  NOTA_DEDITO = "NDBO",
  COPIA_NOTA = "CPNCO",
  COPIA_DOC = "CPDOC",
}

export type RemesaTipo = keyof IRemesa;
export type ServicioTipo = keyof IServicio;

export interface IRemesaCumplido {
  remesa_id: number | null;
  tipoCumplido?: string;
  tipo_cumplido: string;
  fecha_entra_cargue: string;
  fecha_inicia_cargue: string;
  fecha_fin_cargue: string;
  fecha_entra_descargue: string;
  fecha_inicia_descargue: string;
  fecha_fin_descargue: string;
  tipo_transporte: TipoTransporteEnum | null;
  cantidad_cargada: number;
  cantidad_entregada: number;
}

export interface IRemesaParcialTipo {
  fecha_hora_cargue: string;
  fecha_hora_descargue: string;
  cantidad: number;
}

export type RemesaCumplidoTipo = keyof IRemesaCumplido;

export enum CumplidoEnum {
  PENDIENTE = "P",
  AUTORIZADO = "AR",
  AUTORIZADO_MUNICIPAL = "AUR",
  CUMPLIR_CARGUE = "CIC",
  CUMPLIR_DESCARGUE = "CID",
  ANULAR_INICIAL = "ANI",
  CUMPLIR_REMESA = "CU",
  ANULAR_CUMPLIDO_REMESA = "ANCR",
  CUMPLIR_MUNICIPAL = "CUM",
}

export type InterseccionLista = ListasEstandarFormulario & {
  habilitado: boolean;
  dividido?: boolean;
};

export enum MotivoAnulacionEnum {
  ERROR_DIGITACION = "D",
  OTROS = "O",
}

export interface IAnularCumplidoRemesa {
  remesa_id: number;
  motivo_anulacion: MotivoAnulacionEnum;
  observaciones: string;
}

export enum TipoCumplidoEnum {
  CARGUE = "C",
  DESCARGUE = "D",
}

export interface CumplidoMixTipo extends IRemesaCumplido, IRemesaParcialTipo {
  estado?: EstadoDocumento;
  estado_cumplido: CumplidoEnum;
  fecha_cargue: string;
  tipo_transporte: TipoTransporteEnum;
}

export interface IValoresReales {
  valor_real_a_cobrar: number;
  valor_real_a_pagar: number;
  cantidad_real: number;
  remesa_linea_id: number;
  numero_documento: string;
  tipo_servicio: string;
  pendiente?: boolean;
}
